<template>
  <div class="page-box">
    <div class="tree-box">
      <p class="tree-title">模块信息 </p>
      <div class="tree-but-list">
        <Button type="success" @click="handleEdit('add')" class="ml20">添加</Button>
        <Button type="primary" @click="handleEdit('edit')" class="ml20">编辑</Button>
        <Button type="warning" @click="handleEdit('del')" class="ml20">删除</Button>
      </div>
      <Tree :data="dataTree" @on-select-change='selectChange'></Tree>
    </div>
    <div class="con-box">
      <div class="function-box">
        <p class="tree-title">功能列表信息 </p>
        <vxe-table border height="320" ref='xTable1' :row-config="{isHover: true}" @radio-change="GetOperationList" :data="functionList" :radio-config="{labelField: 'name', highlight: true}" :key="indexRefresh">
          <vxe-column type="seq" width="60" ></vxe-column>
          <vxe-column type="radio" title="功能名称" width="300"></vxe-column>
          <vxe-column field="code" title="功能编码"></vxe-column>
          <vxe-column field="remark" title="说明"></vxe-column>
          <vxe-column field="sort" title="排序数字"></vxe-column>
          <vxe-column width="200" fixed="right">
            <template slot="header">
              <Button type="success" @click="functionInfoEdit()">新增</Button>
              <Button type="primary" @click="GetFunctionList()" class="ml10">刷新</Button>
            </template>
            <template #default="{ row }">
              <Button type="primary" @click="functionInfoEdit(row)">修改</Button>
              <Button type="warning" @click="functionInfoDel(row)" class="ml10">删除</Button>
            </template>
          </vxe-column>
        </vxe-table>
      </div>
      <div class="operation-box">
        <p class="tree-title">操作列表信息 </p>
        <vxe-table border height="300" :row-config="{isHover: true}" :data="operationInfoList" :radio-config="{ highlight: true}" :key="indexRefresh">
          <vxe-column type="seq" width="60" ></vxe-column>
          <vxe-column field="name" title="操作名称"></vxe-column>
          <vxe-column field="code" title="操作编码"></vxe-column>
          <vxe-column field="remark" title="说明"></vxe-column>
          <vxe-column field="sort" title="排序数字"></vxe-column>
          <vxe-column width="200" fixed="right">
            <template slot="header">
              <Button type="success" @click="operationInfoEdit()">新增</Button>
              <Button type="primary" @click="GetOperationList()" class="ml10">刷新</Button>
            </template>
            <template #default="{ row }">
              <Button type="primary" @click="operationInfoEdit(row)">修改</Button>
              <Button type="warning" @click="operationInfoDel(row)" class="ml10">删除</Button>
            </template>
          </vxe-column>
        </vxe-table>
      </div>
    </div>
    <moduleInfoEdit :item='contextData' v-model="showModal" @saved='GetModuleList'></moduleInfoEdit>
    <functionInfoPage :item='functionInfoData' v-model="functionInfoModal" @saved='GetFunctionList'></functionInfoPage>
    <operationInfoPage :item='operationInfoData' v-model="operationInfoModal" @saved='GetOperationList'></operationInfoPage>
  </div>
</template>
<script>
import moduleInfoEdit from './moduleInfoEdit'
import functionInfoPage from './functionInfoEdit'
import operationInfoPage from './operationInfoEdit'

export default {
  name: 'GetModuleList',

  components: {
    moduleInfoEdit,
    functionInfoPage,
    operationInfoPage,
  },
  data() {
    return {
      dataTree: [
        {
          title: '',
          expand: true,
        }
      ],
      contextData: {
        isAdd: 0,//0添加顶级 1 添加子级 2修改
        parentId: '',
        data: {},
      },
      list: [],
      showModal: false,
      functionInfoModal: false,
      functionInfoData: {
        isAdd: true,
        moduleId: '',
        data: {},
      },
      functionList: [],
      operationInfoModal: false,
      operationInfoData: {
        isAdd: true,
        moduleId: '',
        data: {},
      },
      operationInfoList: [],
      indexRefresh: 0
    }
  },

  mounted: function () {
    this.GetModuleList();
  },
  methods: {
    handleEdit(el) {
      if (el == 'add') {
        this.contextData.isAdd = 0;
        this.showModal = true;
      }
      if (el == 'edit') {
        this.showModal = true;
        this.contextData.isAdd = 2;
      }
      if (this.contextData.data.length == 1 && el == 'add') {
        this.contextData.isAdd = 1;
        this.contextData.parentId = this.contextData.data[0].id;
        this.showModal = true;
      }
      if (el == 'del') {
        if (this.contextData == []) {
          return this.$Message.info('选中一个操作');
        }
        this.DeleteModuleInfo()
      }
    },
    functionInfoEdit(el) {
      this.functionInfoData.isAdd = true;
      if (el != undefined) {
        this.functionInfoData.isAdd = false;
        this.functionInfoData.data = el;
      }
      this.functionInfoData.moduleId = this.contextData.data[0].id;
      this.functionInfoModal = true;

    },
    DeleteModuleInfo() {
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除单位[ ${this.contextData.data[0].name}] 吗?`,
        onOk: async () => {
          this.$axios.post(`api/auth/DeleteModuleInfo`, { id: this.contextData.data[0].id }).then(res => {
            if (res.code == 0) {
              this.$Message.info("删除成功");
              this.GetModuleList()
            }
          });
        }
      });
    },
    functionInfoDel(el) {
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除单位[ ${el.name}] 吗?`,
        onOk: async () => {
          this.$axios.post(`api/auth/DeleteFunctionInfo`, { id: el.id }).then(res => {
            if (res.code == 0) {
              this.$Message.info("删除成功");
              this.GetFunctionList()
            }
          });
        }
      });
    },
    operationInfoDel(el) {
      this.$Modal.confirm({
        title: "提示",
        content: `确定要删除单位[ ${el.name}] 吗?`,
        onOk: async () => {
          this.$axios.post(`api/auth/DeleteOperationInfo`, { id: el.id }).then(res => {
            if (res.code == 0) {
              this.$Message.info("删除成功");
              this.GetOperationList()
            }
          });
        }
      });
    },
    operationInfoEdit(el) {
      let ps = this.$refs.xTable1.getRadioRecord();
      if (ps == null) {
        this.$Message.info("选中一项功能列表信息进行操作");
        return false
      }
      this.operationInfoData.isAdd = true;
      if (el != undefined) {
        this.operationInfoData.data = el;
        this.operationInfoData.isAdd = false;
      }
      this.operationInfoData.moduleId = ps.id;
      this.operationInfoModal = true;

    },
    selectChange(data) {
      this.contextData.data = data;
      this.GetFunctionList()
    },
    GetFunctionList() {
      this.indexRefresh++;
      this.operationInfoList = [];
      if (this.contextData.data.length == 0) {
        this.functionList = [];
        return false;
      }
      this.$axios.post(`api/auth/GetFunctionList`, { id: this.contextData.data[0].id }).then(res => {
        if (res.code == 0) {
          res.data.sort((a, b) => a.sort - b.sort);
          this.$set(this, 'functionList', res.data)
          this.GetOperationList();
        }
      });
    },
    GetOperationList() {
      let ps = this.$refs.xTable1.getRadioRecord();
      if (ps == null) {
        this.operationInfoList = [];
        return false
      }
      this.$axios.post(`api/auth/GetOperationList`, { id: ps.id }).then(res => {
        if (res.code == 0) {
          res.data.sort((a, b) => a.sort - b.sort);
          this.$set(this, 'operationInfoList', res.data)
        }
      });
    },
    GetModuleList() {
      this.$axios.post(`api/auth/GetModuleList`, {}).then(res => {
        if (res.code == 0) {
          res.data.sort((a, b) => a.sort - b.sort);
          res.data.forEach(el => {
            el.title = el.name + '--' + el.sort + '--' + el.code;
          });
          this.$set(this, 'dataTree', this.buildTree(res.data));
        }
      });
    },
    buildTree(items, parentId = '') {
      return items
        .filter(item => item.parentId === parentId)
        .map(item => ({
          ...item,
          children: this.buildTree(items, item.id),
        }));
    },
  }
}
</script>
<style scoped>
.page-box {
  background-color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
}
.tree-box {
  width: 300px;
  height: 100%;
  padding: 20px;
  overflow-y: auto;
}
.tree-title {
  color: #3f75ff;
  font-family: Source Han Sans CN;
  font-weight: 500;
  font-size: 20px;
  margin: 0 0 20px;
}
.tree-but-list {
  text-align: right;
}
.con-box {
  width: calc(100% - 300px);
  height: 100%;
  border: 1px solid #999989;
  box-sizing: border-box;
  overflow-y: auto;
  padding: 20px;
}
.function-box,
.operation-box {
  width: 100%;
}
</style>
