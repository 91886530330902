<template>
  <Modal v-model="showModal" :mask-closable="false" width="500px">
    <div slot="header">模块信息</div>
    <div>
      <Form ref="form" :model="form" :label-width="100">
        <FormItem prop="name" label="模块名称">
          <Input v-model="form.name" style="width: 270px"></Input>
        </FormItem>
        <FormItem prop="code" label="识别编码">
          <Input v-model="form.code" style="width: 270px"></Input>
        </FormItem>
        <FormItem prop="parentId" label="上级模块">
          <Input v-model="form.parentId" style="width: 270px" disabled></Input>
        </FormItem>
        <FormItem prop="remark" label="模块说明">
          <Input v-model="form.remark" style="width: 270px"></Input>
        </FormItem>
        <FormItem prop="sort" label="排序数字">
          <Input v-model="form.sort" style="width: 270px"></Input>
        </FormItem>
      </Form>
    </div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="showModal=false">取消</Button>
      <Button type="primary" @click="ok">保存</Button>
    </div>
  </Modal>
</template>
<script>

export default {
  name: 'moduleInfoEdit',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showModal: false,
      form: {
        "id": '',
        "name": "",
        "code": "",
        "parentId": "",
        "remark": "",
        "sort": 1,
      }
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (this.item.isAdd == 0 && this.showModal) {
        this.form = {
          "id": '',
          "name": "",
          "code": "",
          "parentId": "",
          "remark": "",
          "sort": 1,
        }
      }
      if (this.item.isAdd == 1 && this.showModal) {
        this.form = {
          "id": '',
          "name": "",
          "code": "",
          "parentId": this.item.parentId,
          "remark": "",
          "sort": 1,
        }
      }
      if (this.item.isAdd == 2 && this.showModal) {
        this.form = {
          "id": this.item.data[0].id,
          "name": this.item.data[0].name,
          "code": this.item.data[0].code,
          "parentId": this.item.data[0].parentId,
          "remark": this.item.data[0].remark,
          "sort": this.item.data[0].sort,
        }
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
  },
  mounted: function () {
  },
  methods: {
    ok() {
      this.form.sort = this.form.sort * 1;
      this.$axios.post(`api/auth/SaveModuleInfo`, this.form).then(res => {
        if (res.code == 0) {
          this.$Message.info('设置成功');
          this.$emit("saved")
        }
      });
    },
  }
}
</script>
<style scoped>
.page-box {
  background-color: #fff;
  width: 100%;
  height: 100%;
}
.alarm-data-area {
  width: 100%;
}
</style>
