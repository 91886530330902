<template>
  <div class="page-box">
    <Form ref="form" :model="form" :label-width="250">
      <FormItem prop="paramId" label="是否启用短信通知功能">
        <i-switch v-model="form.enable" size="large" true-color="green" false-color="red">
          <span slot="open">开启</span>
          <span slot="close">禁用</span>
        </i-switch>
      </FormItem>
      <FormItem prop="appId" label="短信通知应用ID">
        <Input v-model="form.appId" style="width: 260px"></Input>
      </FormItem>
      <FormItem prop="appSecret" label="短信通知应用密钥">
        <Input v-model="form.appSecret" style="width: 260px"></Input>
      </FormItem>
      <FormItem prop="signName" label="短信通知企业简称">
        <Input v-model="form.signName" style="width: 260px"></Input>
      </FormItem>
      <FormItem prop="templateAlarm" label="报警短信通知模板号">
        <Input v-model="form.templateAlarm" style="width: 260px"></Input>
      </FormItem>
      <FormItem prop="templateRegist" label="注册短信通知模板号">
        <Input v-model="form.templateRegist" style="width: 260px"></Input>
      </FormItem>

      <FormItem prop="templateLogin" label="登录短信通知模板号">
        <Input v-model="form.templateLogin" style="width: 260px"></Input>
      </FormItem>
      <FormItem prop="templateModify" label="修改密码短信通知模板号">
        <Input v-model="form.templateModify" style="width: 260px"></Input>
      </FormItem>
      <FormItem prop="templateOrder" label="待处理工单短信通知模板号">
        <Input v-model="form.templateOrder" style="width: 260px"></Input>
      </FormItem>
      <FormItem prop="voiceAlarm" label="待处理工单语音通知模板号">
        <Input v-model="form.voiceAlarm" style="width: 260px"></Input>
      </FormItem>

      <FormItem prop="voiceWaitOrder" label="已完成工单语音通知模板号">
        <Input v-model="form.voiceWaitOrder" style="width: 260px"></Input>
      </FormItem>
      <FormItem prop="voiceDoneOrder" label="报警语音通知模板号">
        <Input v-model="form.voiceDoneOrder" style="width: 260px"></Input>
      </FormItem>

      <FormItem prop="expired" :label-width="250">
        <Button type="success" @click="saveSetting">保存</Button>
      </FormItem>
    </Form>
  </div>
</template>
<script>

export default {
  name: 'weixin',
  components: {
  },
  data() {
    return {
      form: {
        "enable": false,
        "appId": "",
        "appSecret": "",
        "signName": "",
        "templateAlarm": "",
        "templateRegist": "",
        "templateLogin": "",
        "templateModify": "",
        "templateOrder": "",
        "voiceAlarm": "",
        "voiceWaitOrder": "",
        "voiceDoneOrder": ""
      }
    }
  },
  mounted: function () {
    this.GetMessageSetting();
  },
  methods: {
    GetMessageSetting() {
      this.$axios.post(`api/sys/GetMessageSetting`, {}).then(res => {
        if (res.code == 0) {
          this.form = Object.assign({}, this.form, res.data)
        }
      });
    },
    saveSetting() {
      this.$axios.post(`api/sys/SetMessageSetting`, this.form).then(res => {
        if (res.code == 0) {
          this.$Message.info('设置成功');
        }
      });
    }
  }
}
</script>
<style scoped>
.page-box {
  background-color: #fff;
  width: 100%;
  height: 100%;
  padding-top: 20px;
}
</style>
