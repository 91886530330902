<template>
  <Modal v-model="showModal" :mask-closable="false" width="500px">
    <div slot="header">操作信息</div>
    <div>
      <Form ref="form" :model="form" :label-width="100">
        <FormItem prop="name" label="操作名称">
          <Input v-model="form.name" style="width: 260px"></Input>
        </FormItem>
        <FormItem prop="code" label="识别编码">
          <Input v-model="form.code" style="width: 260px"></Input>
        </FormItem>
        <FormItem prop="remark" label="操作说明">
          <Input v-model="form.remark" style="width: 260px"></Input>
        </FormItem>
        <FormItem prop="sort" label="排序数字">
          <Input v-model="form.sort" style="width: 260px"></Input>
        </FormItem>
      </Form>
    </div>
    <div slot="footer">
      <Button style="margin-right: 8px" @click="showModal=false">取消</Button>
      <Button type="primary" @click="ok">保存</Button>
    </div>
  </Modal>
</template>
<script>

export default {
  name: 'functionInfoEdit',
  components: {
  },
  model: {
    prop: 'value',
    event: 'showChanged'
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default() { return {}; }
    },
  },
  data() {
    return {
      showModal: false,
      form: {
        "id": "",
        "code": "",
        "name": "",
        "moduleId": "",
        "remark": "",
        "sort": 1, //排序数字
      }
    }
  },
  watch: {
    value(newVal) {
      this.showModal = newVal;
      if (this.showModal) {
        if (this.item.isAdd) {
          this.form = {
            "id": "",
            "code": "",
            "name": "",
            "functionId": this.item.moduleId,
            "remark": "",
            "sort": 1, //排序数字
          }
        } else {
          this.form = {};
          this.$set(this, 'form', this.item.data)
        }
      }
    },
    showModal(val) {
      this.$emit('showChanged', val);
    },
  },
  mounted: function () {
  },
  methods: {
    ok() {
      this.form.sort = this.form.sort * 1;
      this.$axios.post(`api/auth/SaveOperationInfo`, this.form).then(res => {
        if (res.code == 0) {
          this.$Message.info('设置成功');
          this.$emit("saved")
        }
      });
    },
  }
}
</script>
<style scoped>
.page-box {
  background-color: #fff;
  width: 100%;
  height: 100%;
}
.alarm-data-area {
  width: 100%;
}
</style>
